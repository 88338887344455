// import Dropzone from 'dropzone';
import './style.scss';

window.addEventListener('load', () => {
	// const graph = document.querySelector('das-graph');

	// let dropZone = new Dropzone("form#upload", {
	// 	// disablePreviews: true,
	// 	uploadMultiple: false,
	// 	autoProcessQueue: false,
	// 	parallelUploads: 100,
	// 	maxFiles: 100,

	// });

	// const submit = document.querySelector("button[type=submit]");
	// submit.addEventListener("click", (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	dropZone.processQueue();
	// });

	// dropZone.on("sendingmultiple", () => {
	// 	console.log("sending");
	// });

	// dropZone.on("successmultiple", (files, response) => {
	// 	console.log("succesfully uploaded files");

	// 	if (response.error !== undefined) {
	// 		alert(response.error);
	// 	}
	// 	else if (response.jobId !== undefined)
	// 	{
	// 		window.location.href = `/result/${response.jobId}`;
	// 	}
	// 	else {
	// 		alert("No valid job ID was received from the server");
	// 	}
	// });

	// dropZone.on("errormultiple", (files, response) => {
	// 	console.log(`failed: ${response}`);
	// });
});
